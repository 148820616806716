import React, {useEffect, useState} from 'react';
import './Tasks.css';
import axios from "axios";
import {id, url} from "../pages/Go";

const Tasks = ({tasksModal, setTasksModal, power, setPower, setTasksAmount, setTasksCompleted}) => {



    const [tasksArray, setTasksArray] = useState([]);
    const [tasksSchemaArray, setTasksSchemaArray] = useState([]);

    const getTasksRequest = () => {

        axios.post(`${url}/api/get_tasks`, {
            id: id
        })
            .then(response => {

                setTasksArray(response.data);

                setTasksCompleted(response.data.filter(f => f.claimed)?.length);

            })
            .catch(error => {
                console.log(error);
            });
    };

    const getTasksSchemaRequest = () => {

        axios.get(`${url}/api/get_tasks_schema`)
            .then(response => {

                setTasksSchemaArray(response.data);
                setTasksAmount(response.data.length);

                getTasksRequest();


            })
            .catch(error => {
                console.log(error);
            });
    };
    
    
    const completeTaskRequest = (task_id) => {

        axios.post(`${url}/api/complete_task`, {
            id: id,
            task_id: task_id,
        })
            .then(response => {

                getTasksRequest();

            })
            .catch(error => {
                console.log(error);
            });
        
    }


    const claimTaskRequest = (task_id) => {

        axios.post(`${url}/api/claim_task`, {
            id: id,
            task_id: task_id,
        })
            .then(response => {

                setPower(response.data);

                getTasksRequest();

            })
            .catch(error => {
                console.log(error);
            });

    }
    
    
    


    const tasks = [

        {
            id: 1,
            title: 'JOIN ON TWITTER',
            reward: 30,
            completed: tasksArray?.find(task => task.id === 1)?.completed || false,
            claimed: tasksArray?.find(task => task.id === 1)?.claimed || false,
        },

        {
            id: 2,
            title: 'POST 4 STORIES',
            reward: 60,
            completed: tasksArray?.find(task => task.id === 2)?.completed || false,
            claimed: tasksArray?.find(task => task.id === 2)?.claimed || false,
        },

        {
            id: 4,
            title: 'INVITE 3 FRIENDS',
            reward: 80,
            completed: tasksArray?.find(task => task.id === 4)?.completed || false,
            claimed: tasksArray?.find(task => task.id === 4)?.claimed || false,
        },

        {
            id: 3,
            title: 'POST 20 STORIES',
            reward: 360,
            completed: tasksArray?.find(task => task.id === 3)?.completed || false,
            claimed: tasksArray?.find(task => task.id === 3)?.claimed || false,
        },

        {
            id: 5,
            title: 'INVITE 15 FRIENDS',
            reward: 480,
            completed: tasksArray?.find(task => task.id === 5)?.completed || false,
            claimed: tasksArray?.find(task => task.id === 5)?.claimed || false,
        },

    ]



    









    useEffect(() => {

        getTasksSchemaRequest();

    }, [])


    const joinTwitter = () => {

        window.open('https://x.com', '_blank')
        setTimeout(() => {
            completeTaskRequest(1)
        }, 500)

    }



    return (


        <div className={`${tasksModal ? 'active' : ''} tasks-wrapper modal-wrapper`}>

            <div className={'tasks-container'}>

                {
                    tasks.map((task, index) =>
                        <div className={'task-item'} key={index}>

                            <div className={'header-left'}>
                                <h3 className={'black'}>
                                    {task.title}
                                </h3>
                                <div className={'header-left-bottom'}>
                                    <p className={'black'}>
                                        +{task.reward} TonGO
                                    </p>
                                </div>
                            </div>

                            <button
                                className={`${(!task.completed && task.id !== 1) || task.claimed ? 'disabled' : ''} btn white`}
                                onTouchEnd={() => {
                                    task.completed ?
                                        claimTaskRequest(task.id)
                                        :
                                        task.id === 1 ?
                                            joinTwitter()
                                            :
                                            completeTaskRequest(task.id)
                                }}
                            >
                                {
                                    task.claimed ?
                                        'CLAIMED'
                                        :
                                        task.completed ?
                                            'CLAIM'
                                            :
                                            task.id === 1 ?
                                                'JOIN'
                                                :
                                                'CLAIM'
                                }
                            </button>

                        </div>
                    )
                }

            </div>

            <button
                className={'btn full black'}
                onTouchEnd={() => setTasksModal(false)}
            >
                CLOSE
            </button>

        </div>


    );
};

export default Tasks;