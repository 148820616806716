import {BrowserRouter, Route, Routes, useLocation, useRoutes} from "react-router-dom";
import Go from "./pages/Go";
import Home from "./components/Home";
import {AnimatePresence} from "framer-motion";
import React from "react";

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/go" element={<Go />} />
            </Routes>
        </BrowserRouter>
  );
}

export default App;
