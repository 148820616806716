import React from 'react';
import './ProgressBar.css';
import {is_premium, username} from "../pages/Go";

const ProgressBar = ({referralsCount}) => {

    const progress = referralsCount;

    return (
        <div className={'progress-bar-wrapper'}>

            {/*<div className={'progress-bar-top'}>

                <p className={'graylight'}>
                    Level 1
                </p>

                <div className={'progress-bar-top-left'}>
                    <p className={'purple'}>

                    </p>
                    <p className={'graylight'}>
                        3 / 10
                    </p>

                </div>

            </div>*/}

            <div className={'progress-bar-bottom'}>


                <div className={'progress-bar-container'}>

                    <div className={'progress-bar-active'} style={{width: `${progress * 10}%`}}>
                        {/*<p className={'gray'}>
                            {progress}
                        </p>*/}
                    </div>

                    <div className={'progress-bar-remaining'} style={{width: `${(10 - progress) * 10}%`}}>
                        {/*<p className={'white'}>
                            {10 - progress}
                        </p>*/}
                    </div>

                </div>

                {/*<button className={'flexible small btn purple'} style={{width: '30px', minWidth: '30px'}}>
                    BOOST
                </button>*/}

            </div>

            <div className={'progress-bar-bottom-bottom'}>
                {/*<h3>
                    LVL 1.
                    &nbsp;
                </h3>
                <h3 className={'purple'}>
                    REFS 2/10.
                </h3>*/}
                <button className={'full btn purple'} style={{justifyContent: 'space-between', flexDirection: 'row', padding: '0 30px', paddingTop: '1px'}}>
                    <span>LVL 1</span><span>FRIENDS {progress}/10</span>
                </button>
            </div>

        </div>
    );
};

export default ProgressBar;