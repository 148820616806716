import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import './Go.css';
import Welcome from "../components/Welcome";
import Main from "./Main";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Board from "./Board";
import Rank from "./Rank";


export const url = "https://tongo.network"
// export const url = "http://localhost:8080"

export const tg = window.Telegram.WebApp;

export const id = window.Telegram.WebApp.initDataUnsafe.user?.id || 6935019187;
export const username = window.Telegram.WebApp.initDataUnsafe.user?.username || 'gorinoleggg';
export const first_name = window.Telegram.WebApp.initDataUnsafe.user?.first_name || 'First Name';
export const last_name = window.Telegram.WebApp.initDataUnsafe.user?.last_name;
export const start_param = window?.Telegram?.WebApp?.initDataUnsafe?.start_param;
export const is_premium = window.Telegram.WebApp.initDataUnsafe.user?.is_premium == true ? 1 : 0;

export const haptic = tg.HapticFeedback;

export const referralShareLink = `https://t.me/share/url?url=https://t.me/tongonetworkbot/tongo?startapp=${id}`;
export const referralCopyLink = `https://t.me/tongonetworkbot/tongo?startapp=${id}`;


// const hh1 = document.getElementById('hh1');
const hh1 = 0;


const Go = () => {

    useEffect(() => {

        tg.setHeaderColor('#000000');
        tg.setBackgroundColor('#000000');
        tg.setBottomBarColor('#000000');

        tg.enableClosingConfirmation();
        tg.disableVerticalSwipes();
        tg.ready();
        tg.expand();

        authRequest();

    }, []);


    const [welcomeModal, setWelcomeModal] = useState(false);



    // USER

    const [userData, setUserData] = useState({});

    const [dataCollected, setDataCollected] = useState(false);

    const [isSubscribed, setIsSubscribed] = useState(0);
    const [isNew, setIsNew] = useState(false);

    const [balance, setBalance] = useState(120);
    const [power, setPower] = useState(240);

    const authRequest = () => {

        axios.post(`${url}/api/auth`, {
            id: id,
            name: first_name,
            surname: last_name,
            username: username,
            invited_by: start_param,
            is_premium: is_premium,
            tg_data: window.Telegram.WebApp.initData || '123',
        })
            .then(response => {

                setUserData(response.data[0]);

                setIsNew(response.data[1] === 'new');
                setBalance(+response.data[0].balance);
                setPower(+response.data[0].power);

                setWelcomeModal(true);

                setIsSubscribed(response.data[2]);

                setDataCollected(true);


                getReferrals();
                getRank();

            })
            .catch(error => {
                // alert(error);
            });
    };



    const [referralsArray, setReferralsArray] = useState([]);
    const [referralsCount, setReferralsCount] = useState(0);

    const getReferrals = () => {

        axios.post(`${url}/api/get_referrals`, {
            id: id
        })
            .then(response => {


                setReferralsArray(response.data[0]);
                setReferralsCount(response.data[1]);


            })
            .catch(error => {
                // alert(error);
            });
    };



    const [rankGoArray, setRankGoArray] = useState([]);
    const [rankFriendsArray, setRankFriendsArray] = useState([]);

    const getRank = () => {

        axios.get(`${url}/api/get_rank`)
            .then(response => {

                setRankGoArray(response.data[0]);
                setRankFriendsArray(response.data[1]);


            })
            .catch(error => {
                // alert(error);
                console.log(error);
            });
    };


    useEffect(() => {

        const interval = setInterval(() => {

            if (!isSubscribed) {

                authRequest();

            }

        }, 3000);

        return () => {
            clearInterval(interval);
        }

    }, [isSubscribed]);


    const h1Ref = useRef(null);

    // const [topOffsetX, setTopOffset] = useState(hh1?.getBoundingClientRect()?.top);

    const topOffset = 30;

    const [navActive, setNavActive] = useState(0);

    return (
        <div className={'go-wrapper'} style={{overflowY: navActive === 0 ? 'hidden' : 'scroll'}}>

            {
                isSubscribed ?
                    <div>
                        <Header />


                        <Board
                            topOffset={topOffset}
                            active={navActive === 1}
                            referralsArray={referralsArray}
                            referralsCount={referralsCount}
                            userData={userData}
                            power={power}
                            setPower={setPower}
                        />

                        <Rank
                            topOffset={topOffset}
                            active={navActive === 2}
                            rankGoArray={rankGoArray}
                            rankFriendsArray={rankFriendsArray}
                        />

                        <Navbar
                            navActive={navActive}
                            setNavActive={setNavActive}
                        />

                        <Main
                            h1Ref={h1Ref}
                            active={navActive === 0}
                            balance={balance}
                            setBalance={setBalance}
                            power={power}
                            setPower={setPower}
                            referralsCount={referralsCount}
                        />



                    </div>
                    :
                    <Welcome
                        welcomeModal={welcomeModal}
                        setWelcomeModal={setWelcomeModal}
                        isSubscribed={isSubscribed}
                    />
            }

            {/*<Welcome
                welcomeModal={welcomeModal}
                setWelcomeModal={setWelcomeModal}
                isSubscribed={isSubscribed}
            />

            <Main />*/}

        </div>
    );
};

export default Go;