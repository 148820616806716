import React, {useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import './Rank.css';
import coin from '../assets/coin_dark.svg';

const Rank = ({active, topOffset, rankGoArray, rankFriendsArray}) => {


    const [rankToggle, setRankToggle] = useState(true);


    return (
        <AnimatePresence>
            {active && (
                <motion.div
                    className={"main-wrapper rank-wrapper"}
                    key="rank"
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    style={{
                        opacity: active ? 1 : 0,
                        filter: active ? 'brightness(100%)' : 'brightness(0%)',
                        paddingTop: `calc(${topOffset}px)`,
                        // display: active ? 'flex' : 'none',
                    }}
                >

                    <div className={'rank-header'}>
                        <h1 className={'purple'}>
                            RANK
                        </h1>

                        <p className={'white'}>
                            Invite Friends, grow Network and increase GO Power!
                        </p>
                    </div>


                    <div
                        className={'rank-container-big'}
                        style={{
                            marginTop: `calc(${topOffset}px)`
                        }}
                    >

                        <div className={'toggle full gray'}>

                            <button
                                className={`full btn ${rankToggle ? 'white z10' : 'gray z1'}`}
                                onClick={() => setRankToggle(true)}
                            >
                                BY GO
                            </button>

                            <button
                                className={`full btn ${!rankToggle ? 'white z10' : 'gray z1'}`}
                                onClick={() => setRankToggle(false)}
                            >
                                BY FRIENDS
                            </button>
                        </div>

                        <div className={'rank-container'}>

                            {
                                (rankToggle ? rankGoArray : rankFriendsArray)?.map((r, i) =>
                                    <div className={'rank-friends-container'} key={i}>

                                        <div className={'rank-friend-left'}>
                                            <h3 className={'white'}>
                                                {r.username || 'user'}
                                            </h3>
                                        </div>

                                        <div className={'rank-friend-right'}>
                                            <h3 className={'purple'}>
                                                {rankToggle ? r.balance : r.referrals_count}
                                            </h3>
                                        </div>

                                    </div>
                                )
                            }

                        </div>

                        <div className={'rank-mb-dummie'}>
                            &nbsp;
                        </div>

                        {/*<div
                            className={'invite-wrapper'}
                            // style={{marginBottom: `calc(${topOffset}px - var(--headerheight) - var(--blockpadding))`}}
                        >

                            <button className={'invite-share-container btn purple'} style={{width: '70vw'}}>
                                INVITE FRIENDS
                            </button>

                            <button className={'invite-share-container btn gray'}>
                                COPY LINK
                            </button>

                        </div>*/}

                    </div>


                </motion.div>
            )}
        </AnimatePresence>
    )

};

export default Rank;