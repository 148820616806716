import React, {useState} from 'react';
import './Main.css';
import '../components/Modal.css';
import Story from "../components/Story";
import {first_name, haptic, id, is_premium, last_name, start_param, tg, url, username} from "./Go";
import logo from '../assets/logo_dry.svg';
import coin from '../assets/coin.svg';
import ProgressBar from "../components/ProgressBar";
import {AnimatePresence, motion} from "framer-motion"
import axios from "axios";

const Main = ({active, h1Ref, balance, setBalance, power, setPower, referralsCount}) => {

    const [storyModal, setStoryModal] = useState(false);


    return (
        <>
            {active && (
                <motion.div
                    className={'main-wrapper'}
                    key="go"
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    style={{
                        opacity: active ? 1 : 0,
                        filter: active ? 'brightness(100%)' : 'brightness(0%)',
                        // display: active ? 'flex' : 'none',
                    }}
                >

                    <Story storyModal={storyModal} setStoryModal={setStoryModal} setBalance={setBalance} power={power}/>


                    <div className={'main-top'}>

                        <div className={'dummie'}></div>

                        <div className={'balance-container'}>
                            <h1 id={'hh1'} className={'balance-h1 purple'}>
                                {/*<img src={coin} alt="coin" />*/}
                                {balance.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                {/*&nbsp;*/}
                                <span className={'white'}>GO</span>
                            </h1>

                            <div className={'go-power-wrapper'}>
                                <p className={'white'}>
                                    {power} <span className={'purple'}>GO POWER</span>
                                </p>
                            </div>


                        </div>


                        <motion.div
                            whileTap={{scale: 0.95}}
                            className={'go-button'}
                            onTouchEnd={() => {
                                setStoryModal(true);
                                haptic.impactOccurred('light');
                            }}
                        >
                            <img src={logo}/>
                        </motion.div>


                        <ProgressBar referralsCount={referralsCount}/>

                        <div className={'dummie'}></div>

                    </div>

                </motion.div>
            )}
        </>
    );
};

export default Main;