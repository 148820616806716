import React, {useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import './Board.css';
import {is_premium, referralCopyLink, referralShareLink, username} from "./Go";
import Tasks from "../components/Tasks";

const Board = ({active, topOffset, referralsArray, referralsCount, userData, power, setPower}) => {

    const [tasksModal, setTasksModal] = useState(false);

    const [tasksCompleted, setTasksCompleted] = useState(0);
    const [tasksAmount, setTasksAmount] = useState(0);

    return (
        <AnimatePresence>

            <Tasks
                tasksModal={tasksModal}
                setTasksModal={setTasksModal}
                power={power}
                setPower={setPower}
                setTasksCompleted={setTasksCompleted}
                setTasksAmount={setTasksAmount}
            />

            {active && (
                <motion.div
                    className={"board-wrapper main-wrapper"}
                    key="board"
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    style={{
                        opacity: active ? 1 : 0,
                        filter: active ? 'brightness(100%)' : 'brightness(0%)',
                        paddingTop: `calc(${topOffset}px)`,
                        // display: active ? 'flex' : 'none',
                    }}
                >

                    <div className={'rank-header'}>
                        <h1 className={'purple'}>
                            BOARD
                        </h1>

                        <p className={'white'}>
                            View data about posted stories, invited friends and completed tasks!
                        </p>
                    </div>


                    <div
                        className={'board-stats-container'}
                        style={{
                            marginTop: `calc(${topOffset}px)`
                        }}
                    >

                        <div className={'board-stats-item'}>
                            <h3 className={'white'}>
                                NAME
                            </h3>
                            <h3 className={'purple'}>
                                {username}
                            </h3>
                        </div>

                        <div className={'board-stats-item'}>
                            <h3 className={'white'}>
                                LEVEL
                            </h3>
                            <h3 className={'purple'}>
                                {userData.level}
                            </h3>
                        </div>

                        <div className={'board-stats-item'}>
                            <h3 className={'white'}>
                                STATUS
                            </h3>
                            <h3 className={'purple'}>
                                {is_premium ? 'PREMIUM' : 'STANDARD'}
                            </h3>
                        </div>

                        <div className={'board-stats-item'}>
                            <h3 className={'white'}>
                                GO POWER
                            </h3>
                            <h3 className={'purple'}>
                                {userData.power}
                            </h3>
                        </div>

                        <div className={'board-stats-item'}>
                            <h3 className={'white'}>
                                STORIES POSTED
                            </h3>
                            <h3 className={'purple'}>
                                {userData.stories_posted}
                            </h3>
                        </div>

                    </div>





                    <div className={'rank-container purple'}>

                        <div className={'rank-friends-container'}>

                            <div className={'rank-friend-left'}>
                                <h3 className={'black'}>
                                    COMPLETED TASKS
                                </h3>
                            </div>

                            <div className={'rank-friend-right'}>
                                <h3 className={'black'}>
                                    {tasksCompleted}/{tasksAmount}
                                </h3>
                            </div>

                        </div>

                        <button
                            className={'btn white full first'}
                            onTouchEnd={() => setTasksModal(true)}
                        >
                            VIEW TASKS
                        </button>

                    </div>




                    <div className={'rank-container'}>

                        <div className={'rank-friends-container'}>

                            <div className={'rank-friend-left'}>
                                <h3 className={'white'}>
                                    FRIENDS
                                </h3>
                            </div>

                            <div className={'rank-friend-right'}>
                                <h3 className={'purple'}>
                                    {referralsCount}
                                </h3>
                            </div>

                        </div>

                        <div className={'rank-friends-container'}>

                            <div className={'rank-friend-left'}>
                                <h3 className={'white'}>
                                    GO FROM FRIENDS
                                </h3>
                            </div>

                            <div className={'rank-friend-right'}>
                                <h3 className={'purple'}>
                                    {userData.go_from_referrals}
                                </h3>
                            </div>

                        </div>


                        <a href={referralShareLink}>
                            <button className={'btn purple full first'}>
                                INVITE
                            </button>
                        </a>

                        <a onClick={() => {
                            navigator.clipboard.writeText(referralCopyLink);
                        }}>
                            <button className={'btn purple empty full'}>
                                COPY LINK
                            </button>
                        </a>

                    </div>


                    <div
                        className={'rank-container'}
                        style={{display: referralsArray.length > 0 ? 'flex' : 'none'}}
                    >

                        {
                            referralsArray?.map((r, i) =>
                                <div className={'rank-friends-container'} key={i}>

                                    <div className={'rank-friend-left'}>
                                        <h3 className={'white'}>
                                            {r.username || 'user'}
                                        </h3>
                                    </div>

                                    <div className={'rank-friend-right'}>
                                        <h3 className={'purple'}>
                                        {r.balance}
                                        </h3>
                                    </div>

                                </div>
                            )
                        }

                    </div>

                    <div className={'rank-mb-dummie'}>
                        &nbsp;
                    </div>


                </motion.div>
            )}
        </AnimatePresence>
    )

};

export default Board;